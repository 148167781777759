import { Box, Container, Typography } from '@mui/material'
import React, { useEffect, Suspense } from 'react'
import NavBar from './components/navBar/NavBar'
import './css/App.css'
import RCMRoutes from './routes'
import queryString from 'query-string'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import moment from 'moment'
import { useStoreActions } from 'easy-peasy'

const package_json = require('../package.json');

// import { Snackbar } from '@mui/material'
// import { Alert } from '@mui/material'
// import { useTranslation } from 'react-i18next'

// import { useState } from 'react'

const App = () => {
  // const { t } = useTranslation()

  // const [showSnackbar, setShowSnackbar] = useState(false)
  // const [snackbarMessage, setSnackbarMessage] = useState('')

  const getContextAction = useStoreActions(actions => actions.getContext)

  let refreshIntervalTimer = null
  // let aliveIntervalTimer = null

  const redirectToLogin = () => {
    window.location.replace(`${window.__RUNTIME_CONFIG__.SSO_URL}/loginrcm#${window.location.origin}`)
  }

  const redirectTo = (url) => {
    window.location.replace(url)
  }

  const logout = () => {
    localStorage.removeItem('jwt')
    localStorage.removeItem('currentTenantId')
    localStorage.removeItem('currentTenantName')
    redirectToLogin()
  }

  const checkAuth = async () => {
    let jwt = localStorage.getItem('jwt')
    let newUrl = window.location.href.substring(0, window.location.href.indexOf('?token='));

    const parsed = queryString.parse(window.location.search)

    if (typeof parsed.token === 'string') {
      jwt = parsed.token.replace("\"", "").replace("\"", "")
      try {
        localStorage.setItem('jwt', jwt)
        window.history.replaceState({}, null, newUrl)
      } catch (e) {
        console.error("Error: App.js - Could not set jwt to localStorage", e)
      }
    }

    if (jwt) {
      try {
        const user = jwtDecode(jwt);
        console.log('App.js user', user)

        if (moment().isAfter(moment(user.exp * 1000))) {
          logout()
          return
        }

        redirectOnFirstLogin(jwt)
      } catch (e) {
        console.error("Error: App.js", e)
      }
      refreshToken()
    } else {
      redirectToLogin()
    }
  }

  const refresh = () => {
    var that = this
    axios.get(`${window.__RUNTIME_CONFIG__.SSO_URL}/auth/refresh-token`, {
      headers: { authorization: localStorage.getItem('jwt'), tenant: localStorage.getItem('currentTenantId') }
    }).then(res => {
      console.log('res', res)
      if (res.access_token) {
        localStorage.setItem('jwt', res.access_token);
      }
    }).catch(function (err) {
      console.log('err', err.response)
      if (err.response.status === 401) {
        window.location.replace(`${window.__RUNTIME_CONFIG__.SSO_URL}/loginrcm#${window.location.href}`);
      }
      clearInterval(that.refreshInterval)
      logout()
    })
  }

  const redirectOnFirstLogin = async (jwt) => {
    const context = await getContextAction(jwt)

    if (context.roles && Array.isArray(context.roles) && (!(context.roles.includes('ServiceManager') || context.roles.includes('Admin')))) {
      console.log("Currently only Admins or ServiceManagers are allowed in here. You are neither. Logging you out...")
      logout()
      return false
    }

    if (context.roles && Array.isArray(context.roles) && context.roles.includes('Admin')) {
      if (!localStorage.getItem('currentTenantId') && window.location.href.substring(window.location.href.length - 8) !== '/tenants') {
        redirectTo('/tenants')
      }
    } else {
      const url = new URL(window.location.href)
      if (url.pathname === '/') {
        redirectTo('/tasks')
      }
    }
  }

  const refreshToken = () => {
    clearInterval(refreshIntervalTimer)
    refreshIntervalTimer = setInterval(refresh, 90000000)
  }

  // const closeSnackbar = () => {
  //   setShowSnackbar(false)
  // }

  // const checkAlive = async () => {
  //   if(!aliveIntervalTimer) {
  //     aliveIntervalTimer = setInterval(checkAlive, 10000)
  //     return
  //   }
  //   try {
  //     await getContextAction()
  //     setShowSnackbar(false)
  //     setSnackbarMessage('')
  //   } catch (e) {
  //     console.log("App.js checkAlive CATCH", e)
  //     // if(e.response && e.response.status === 401) {
  //     //   console.log('LOGOUT')
  //     //   logout()
  //     //   return false
  //     // } else 
  //     if (e.code && e.message) {       
  //       //setSnackbarMessage(JSON.stringify(e))
  //       setSnackbarMessage(t(e.message))
  //       setShowSnackbar(true)
  //     }
  //   }
  // }

  useEffect(() => {
    //checkAlive()
    checkAuth()
  })

  return (
    <>
      <Suspense>
        <NavBar />
        <Container maxWidth="xl">
          <Box sx={{ marginBottom: 2 }}>
            <RCMRoutes />
          </Box>
          <Typography color="primary" sx={{ textAlign: 'center', pt: '0.5rem' }}>
            {'Copyright © '}
            {new Date().getFullYear()}
          </Typography>
          {/* <Typography color="secondary"  sx={{textAlign: 'center', pt: '3rem'}}>
            {window.__RUNTIME_CONFIG__.BASE_URL}
          </Typography>*/}
          <Typography color="primary" sx={{ textAlign: 'center', pt: '1rem' }}>
            {package_json && package_json.version}
          </Typography>
          {/* <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={1000 * 10}
            open={showSnackbar}
            onClose={closeSnackbar}
            key={'top-center-error'}
            severity="error"
          >
            <Alert onClose={closeSnackbar} severity="error" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar> */}
        </Container>
      </Suspense>
    </>
  );
}

export default App